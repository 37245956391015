
import { defineComponent } from 'vue';
import IconButton from '../ui/IconButton.vue';

export default defineComponent({
  name: 'Header',
  props: {
    showSettings: Boolean,
    showBackButton: Boolean,
  },
  components: { IconButton },
});
