import { socket } from '@/main';
import {
  ClientInspectorQuizState,
  ClientPlayerQuizState,
  ClientPlayerState,
  ServerState,
  UserMode,
} from '@shared/types';
import { defineStore } from 'pinia';

export const useStore = defineStore('main', {
  state: () => ({
    mode: 'inspector' as UserMode,
    userModeChangeError: false,
    player: null as ClientPlayerState | null,
    quizState: null as ClientPlayerQuizState | ClientInspectorQuizState | null,
    serverState: null as ServerState | null,
  }),
  actions: {
    setUserMode(mode: UserMode, token?: string, force = false) {
      if (this.mode === mode && !this.userModeChangeError && !force) return;

      if (mode !== 'player') {
        this.player = null;
      }
      if (mode === 'admin') {
        this.quizState = null;
      }
      if (mode !== 'admin') {
        this.serverState = null;
      }

      this.userModeChangeError = false;
      socket.emit('setUserMode', mode, token);
      this.mode = mode;
    },
    userModeChangeErrorOccurred() {
      this.userModeChangeError = true;
    },
    playerStateChanged(player: ClientPlayerState | null) {
      this.player = player;
    },
    playerQuizStateChanged(quizState: ClientPlayerQuizState | null) {
      this.quizState = quizState;
    },
    inspectorQuizStateChanged(quizState: ClientInspectorQuizState | null) {
      this.quizState = quizState;
    },
    serverStateChanged(serverState: ServerState) {
      if (this.mode !== 'admin') return;

      this.serverState = serverState;
    },
    playerGuessChanged(guess: number | null) {
      socket.emit('playerGuessChanged', guess);
    },
    playerGuessing() {
      socket.emit('playerGuessing');
    },
    addPlayer(name: string) {
      socket.emit('addPlayer', name);
    },
    changePlayerName(playerId: string, name: string) {
      socket.emit('changePlayerName', playerId, name);
    },
    changePlayerBroke(playerId: string, broke: boolean) {
      socket.emit('changePlayerBroke', playerId, broke);
    },
    removePlayer(playerId: string) {
      socket.emit('removePlayer', playerId);
    },
    startQuiz(quizId: string) {
      socket.emit('startQuiz', quizId);
    },
    changeQuizState(quizId: string, difference: 1 | -1) {
      socket.emit('changeQuizState', quizId, difference);
    },
    setQuizDisabled(quizId: string, disabled: boolean) {
      socket.emit('setQuizDisabled', quizId, disabled);
    },
    resetQuiz(quizId: string) {
      socket.emit('resetQuiz', quizId);
    },
    setQuizPlayed(quizId: string, played: boolean) {
      socket.emit('setQuizPlayed', quizId, played);
    },
    setQuizGuessing(quizId: string, guessing: boolean) {
      socket.emit('setQuizGuessing', quizId, guessing);
    },
    resetQuizGuesses(quizId: string) {
      socket.emit('resetQuizGuesses', quizId);
    },
    setServerState(serverState: ServerState) {
      socket.emit('setServerState', serverState);
    },
  },
});
