import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Inspector',
    component: () =>
      import(/* webpackChunkName: "inspector" */ '../views/Inspector.vue'),
  },
  {
    path: '/play/:id',
    name: 'Player',
    component: () =>
      import(/* webpackChunkName: "player" */ '../views/Player.vue'),
  },
  {
    path: '/admin',
    name: 'QuestionsOverview',
    component: () =>
      import(
        /* webpackChunkName: "questions-overview" */ '../views/admin/QuestionsOverview.vue'
      ),
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/admin/Settings.vue'),
  },
  {
    path: '/admin/quiz/:id',
    name: 'QuizDetail',
    component: () =>
      import(
        /* webpackChunkName: "quiz-detail" */ '../views/admin/QuizDetail.vue'
      ),
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () =>
      import(/* webpackChunkName: "admin-login" */ '../views/admin/Login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue'
      ),
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
