
import { defineComponent } from 'vue';
import Header from '@/components/layout/Header.vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { mapActions, mapState } from 'pinia';
import { useStore } from './store';
import Loading from './components/ui/Loading.vue';

export default defineComponent({
  name: 'App',
  components: {
    Header,
    Loading,
  },
  inject: ['socket'],
  data: () => ({
    firstRouting: false,
  }),
  computed: {
    ...mapState(useStore, [
      'mode',
      'userModeChangeError',
      'serverState',
      'player',
      'quizState',
    ]),
    isViewLoading() {
      if (
        this.mode === 'admin' &&
        !this.userModeChangeError &&
        !this.serverState &&
        this.$route.name !== 'AdminLogin'
      ) {
        return true;
      }

      if (this.mode === 'player' && !this.player && !this.userModeChangeError) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions(useStore, [
      'setUserMode',
      'userModeChangeErrorOccurred',
      'playerStateChanged',
      'playerQuizStateChanged',
      'inspectorQuizStateChanged',
      'serverStateChanged',
    ]),
    syncUserModeWithRoute(route: RouteLocationNormalizedLoaded, force = false) {
      console.log('sync <->', route, force);
      if (route.name === 'Inspector') {
        this.setUserMode('inspector', undefined, force);
      }
      if (route.name === 'Player') {
        const playerId = Array.isArray(route.params.id)
          ? route.params.id[0]
          : route.params.id;
        this.setUserMode('player', playerId, force);
      }
      if (route.fullPath.startsWith('/admin')) {
        if (this.mode !== 'admin' || force) {
          const adminToken = sessionStorage.getItem('admin-password') || '';
          this.setUserMode('admin', adminToken, force);
          return;
        }

        if (this.userModeChangeError && this.$route.name !== 'AdminLogin') {
          this.$router.replace('/admin/login');
        }
      }
    },
  },
  created() {
    this.socket.on('connect', () => {
      if (!this.firstRouting) return;

      this.syncUserModeWithRoute(this.$route, true);
    });
    this.socket.on('userModeChangeError', this.userModeChangeErrorOccurred);
    this.socket.on('playerStateChanged', this.playerStateChanged);
    this.socket.on('playerQuizStateChanged', this.playerQuizStateChanged);
    this.socket.on('inspectorQuizStateChanged', this.inspectorQuizStateChanged);
    this.socket.on('serverStateChanged', this.serverStateChanged);
  },
  watch: {
    $route(to: RouteLocationNormalizedLoaded) {
      this.syncUserModeWithRoute(to, !this.firstRouting);
      this.firstRouting = true;
    },
    userModeChangeError() {
      if (
        !this.userModeChangeError ||
        this.mode !== 'admin' ||
        this.$route.name === 'AdminLogin'
      ) {
        return;
      }

      this.$router.replace('/admin/login');
    },
  },
  unmounted() {
    this.socket.disconnect();
  },
});
