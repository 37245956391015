import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d1f11bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "show-settings": 
      _ctx.$route.path.toLocaleLowerCase().startsWith('/admin') &&
      _ctx.$route.name !== 'AdminLogin'
    ,
      "show-back-button": _ctx.$route.name === 'QuizDetail',
      "hide-logo": _ctx.$route.name === 'Inspector' && !_ctx.quizState
    }, null, 8, ["show-settings", "show-back-button", "hide-logo"]),
    (_ctx.isViewLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Loading)
        ]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
  ], 64))
}