import { createApp } from 'vue';
import VueFeather from 'vue-feather';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { io, Socket } from 'socket.io-client';
import { createPinia } from 'pinia';
import type { ClientToServerEvents, ServerToClientEvents } from '@shared/types';

const pinia = createPinia();

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  'ws://89.22.101.15:3636'
);

const app = createApp(App)
  .use(pinia)
  .use(router)
  .component(VueFeather.name, VueFeather);

app.provide('socket', socket);

app.mount('#app');

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    socket: typeof socket;
  }
}
