<template>
  <div class="loading-wrapper">
    <img src="@/assets/pokerChip.svg" alt="" class="chip" />
    <img src="@/assets/pokerChip.svg" alt="" class="chip" />
    <img src="@/assets/pokerChip.svg" alt="" class="chip" />
  </div>
</template>

<style lang="scss" scoped>
.loading-wrapper {
  padding: 1rem 0;

  .chip {
    margin-right: 1rem;
    position: relative;
    top: -1rem;
    animation: 1.5s ease chip-animation infinite;

    @keyframes chip-animation {
      0% {
        top: -1rem;
      }

      40%,
      50% {
        top: 1rem;
      }

      90%,
      100% {
        top: -1rem;
      }
    }

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
